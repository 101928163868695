<template>

            <!-- 人脸协议 -->
  <div class="faceProtocol">
    <van-nav-bar
       left-arrow
       fixed
       safe-area-inset-top
       placeholder 
       @click-left="leftClick"
       title="人脸识别服务用户授权书"
        />
    <div v-html="faceProtocol"></div>
  </div>
</template>

<script>
import *as api from"@/utils/api"
export default {
        data() {
            return {
                faceProtocol:''
            }
        },
        methods:{
            leftClick(){
                this.$router.push({
                    name:'face'
                })
            }
        },
 async created(){
        let res = await api.getAgreement("FACE_AUTHENTICATION")
        this.faceProtocol = res.content
        console.log(res);
        }
}
</script>

<style lang="scss" scoped>

 ::v-deep{
    .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
          background: #39394D;
        .van-nav-bar__title{
          color: #f6d0ab;
          font-size: 36px;
        }
        .van-icon{
            font-size: 40px;
            color: #f6d0ab;
        }
        }
     }
    h1{
     font-size: 40px !important;
    }
    h2{
     font-size: 30px !important;
    }
}
</style>